/* AppleSDGothicNeo */
@font-face {
  font-family: "AppleSDGothicNeo";
  font-style: normal;
  font-weight: normal;
  src: url("./webfonts/AppleSDGothicNeoR.eot"); /* IE9 Compat Modes */
  src: local("AppleSDGothicNeo-Regular"), local("AppleSDGothicNeo-Regular"),
    url("./webfonts/AppleSDGothicNeoR.eot?#iefix") format("embedded-opentype"),
    /* IE6-IE8 */ url("./webfonts/AppleSDGothicNeoR.woff") format("woff"),
    /* Modern Browsers */ url("./webfonts/AppleSDGothicNeoR.ttf")
      format("truetype"); /* Safari, Android, iOS */
}
@font-face {
  font-family: "AppleSDGothicNeo";
  font-style: normal;
  font-weight: 500;
  src: url("./webfonts/AppleSDGothicNeoM.eot"); /* IE9 Compat Modes */
  src: local("AppleSDGothicNeo-Medium"), local("AppleSDGothicNeo-Medium"),
    url("./webfonts/AppleSDGothicNeoM.eot?#iefix") format("embedded-opentype"),
    /* IE6-IE8 */ url("./webfonts/AppleSDGothicNeoM.woff") format("woff"),
    /* Modern Browsers */ url("./webfonts/AppleSDGothicNeoM.ttf")
      format("truetype"); /* Safari, Android, iOS */
}
@font-face {
  font-family: "AppleSDGothicNeo";
  font-style: normal;
  font-weight: bold;
  src: url("./webfonts/AppleSDGothicNeoB.eot"); /* IE9 Compat Modes */
  src: local("AppleSDGothicNeo-Bold"), local("AppleSDGothicNeo-Bold"),
    url("./webfonts/AppleSDGothicNeoB.eot?#iefix") format("embedded-opentype"),
    /* IE6-IE8 */ url("./webfonts/AppleSDGothicNeoB.woff") format("woff"),
    /* Modern Browsers */ url("./webfonts/AppleSDGothicNeoB.ttf")
      format("truetype"); /* Safari, Android, iOS */
}
@font-face {
  font-family: "AppleSDGothicNeo";
  font-style: normal;
  font-weight: 600;
  src: url("./webfonts/AppleSDGothicNeoSB.eot"); /* IE9 Compat Modes */
  src: local("AppleSDGothicNeo-SemiBold"), local("AppleSDGothicNeo-SemiBold"),
    url("./webfonts/AppleSDGothicNeoSB.eot?#iefix") format("embedded-opentype"),
    /* IE6-IE8 */ url("./webfonts/AppleSDGothicNeoSB.woff") format("woff"),
    /* Modern Browsers */ url("./webfonts/AppleSDGothicNeoSB.ttf")
      format("truetype"); /* Safari, Android, iOS */
}

/* noto-sans-kr - korean */
@font-face {
  font-family: "NotoSansKR";
  font-style: normal;
  font-weight: 100;
  src: url("./webfonts/noto-sans-kr-v9-korean-100.eot"); /* IE9 Compat Modes */
  src: local("NotoSansKR Regular"), local("NotoSansKR-Regular"),
    url("./webfonts/noto-sans-kr-v9-korean-100.eot?#iefix")
      format("embedded-opentype"),
    /* IE6-IE8 */ url("./webfonts/noto-sans-kr-v9-korean-100.woff2")
      format("woff2"),
    /* Super Modern Browsers */
      url("./webfonts/noto-sans-kr-v9-korean-100.woff") format("woff"),
    /* Modern Browsers */ url("./webfonts/NotoSansKR-Thin.otf")
      format("opentype"),
    /* Safari, Android, iOS */
      url("./webfonts/noto-sans-kr-v9-korean-100.svg#NotoSansKR") format("svg"); /* Legacy iOS */
}
@font-face {
  font-family: "NotoSansKR";
  font-style: normal;
  font-weight: 300;
  src: url("./webfonts/noto-sans-kr-v9-korean-300.eot"); /* IE9 Compat Modes */
  src: local("NotoSansKR Regular"), local("NotoSansKR-Regular"),
    url("./webfonts/noto-sans-kr-v9-korean-300.eot?#iefix")
      format("embedded-opentype"),
    /* IE6-IE8 */ url("./webfonts/noto-sans-kr-v9-korean-300.woff2")
      format("woff2"),
    /* Super Modern Browsers */
      url("./webfonts/noto-sans-kr-v9-korean-300.woff") format("woff"),
    /* Modern Browsers */ url("./webfonts/NotoSansKR-Light.otf")
      format("opentype"),
    /* Safari, Android, iOS */
      url("./webfonts/noto-sans-kr-v9-korean-300.svg#NotoSansKR") format("svg"); /* Legacy iOS */
}
@font-face {
  font-family: "NotoSansKR";
  font-style: normal;
  font-weight: 400;
  src: url("./webfonts/noto-sans-kr-v9-korean-regular.eot"); /* IE9 Compat Modes */
  src: local("NotoSansKR Regular"), local("NotoSansKR-Regular"),
    url("./webfonts/noto-sans-kr-v9-korean-regular.eot?#iefix")
      format("embedded-opentype"),
    /* IE6-IE8 */ url("./webfonts/noto-sans-kr-v9-korean-regular.woff2")
      format("woff2"),
    /* Super Modern Browsers */
      url("./webfonts/noto-sans-kr-v9-korean-regular.woff") format("woff"),
    /* Modern Browsers */ url("./webfonts/NotoSansKR-Regular.otf")
      format("opentype"),
    /* Safari, Android, iOS */
      url("./webfonts/noto-sans-kr-v9-korean-regular.svg#NotoSansKR")
      format("svg"); /* Legacy iOS */
}
@font-face {
  font-family: "NotoSansKR";
  font-style: normal;
  font-weight: 500;
  src: url("./webfonts/noto-sans-kr-v9-korean-500.eot"); /* IE9 Compat Modes */
  src: local("NotoSansKR Medium"), local("NotoSansKR-Medium"),
    url("./webfonts/noto-sans-kr-v9-korean-500.eot?#iefix")
      format("embedded-opentype"),
    /* IE6-IE8 */ url("./webfonts/noto-sans-kr-v9-korean-500.woff2")
      format("woff2"),
    /* Super Modern Browsers */
      url("./webfonts/noto-sans-kr-v9-korean-500.woff") format("woff"),
    /* Modern Browsers */ url("./webfonts/NotoSansKR-Medium.otf")
      format("opentype"),
    /* Safari, Android, iOS */
      url("./webfonts/noto-sans-kr-v9-korean-500.svg#NotoSansKR") format("svg"); /* Legacy iOS */
}
@font-face {
  font-family: "NotoSansKR";
  font-style: normal;
  font-weight: 700;
  src: url("./webfonts/noto-sans-kr-v9-korean-700.eot"); /* IE9 Compat Modes */
  src: local("NotoSansKR Bold"), local("NotoSansKR-Bold"),
    url("./webfonts/noto-sans-kr-v9-korean-700.eot?#iefix")
      format("embedded-opentype"),
    /* IE6-IE8 */ url("./webfonts/noto-sans-kr-v9-korean-700.woff2")
      format("woff2"),
    /* Super Modern Browsers */
      url("./webfonts/noto-sans-kr-v9-korean-700.woff") format("woff"),
    /* Modern Browsers */ url("./webfonts/NotoSansKR-Bold.otf")
      format("opentype"),
    /* Safari, Android, iOS */
      url("./webfonts/noto-sans-kr-v9-korean-700.svg#NotoSansKR") format("svg"); /* Legacy iOS */
}
@font-face {
  font-family: "NotoSansKR";
  font-style: normal;
  font-weight: 900;
  src: url("./webfonts/noto-sans-kr-v9-korean-900.eot"); /* IE9 Compat Modes */
  src: local("NotoSansKR Bold"), local("NotoSansKR-Bold"),
    url("./webfonts/noto-sans-kr-v9-korean-900.eot?#iefix")
      format("embedded-opentype"),
    /* IE6-IE8 */ url("./webfonts/noto-sans-kr-v9-korean-900.woff2")
      format("woff2"),
    /* Super Modern Browsers */
      url("./webfonts/noto-sans-kr-v9-korean-900.woff") format("woff"),
    /* Modern Browsers */ url("./webfonts/NotoSansKR-Black.otf")
      format("opentype"),
    /* Safari, Android, iOS */
      url("./webfonts/noto-sans-kr-v9-korean-900.svg#NotoSansKR") format("svg"); /* Legacy iOS */
}
