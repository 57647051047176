@charset "utf-8";
@import url("./fonts.css");

/* All */
html,
body {
  height: 100vh;
  font-family: "NotoSansKR", sans-serif;
  font-weight: normal;
  line-height: normal;
  letter-spacing: -0.6px;
}

/* 공통 */
.container {
  padding-left: 18px;
  padding-right: 18px;
  padding-top: 30px;
  padding-bottom: 5px;
}
.height_full {
  position: relative;
  height: calc(100vh - 130px);
  overflow-y: auto;
}
.container_bottom {
  height: calc(100vh - 620px);
}
.wrap_header {
  display: flex;
  justify-content: center;
  padding: 15px 15px;
  background: #fff;
}
.wrap_header h1 {
  margin-bottom: 0px;
  margin-right: 20px;
  width: calc(100% - 20px);
  font-size: 18px;
  font-weight: 600;
  line-height: 18px;
  text-align: center;
  color: #1a1a1a;
}
.container h3 {
  margin-bottom: 13px;
  font-size: 20px;
  font-weight: 600;
  color: #1a1a1a;
}
.wrap_gray {
  background-color: #f5f5f5;
}
.wrap_white {
  background: #fff;
  padding: 15px;
}
.previous {
  width: 20px;
  height: 20px;
  background: url(../images/arrow_left_black.svg) no-repeat 50% 50%;
}

.tit_small {
  margin-bottom: 20px;
  text-align: center;
  font-size: 16px;
  font-weight: 600;
  color: #1a1a1a;
}
.tit_sub {
  margin-top: 25px;
  font-size: 15px;
  font-weight: 600;
  color: #1a1a1a;
}
.tit_sub2 {
  padding-bottom: 10px;
  text-align: center;
  font-size: 18px;
  font-weight: 600;
  color: #1a1a1a;
}

.text_alert {
  color: #ff8c89;
}
.txt_blue {
  color: #0090e4;
}
.btn.link_txt {
  display: block;
  width: 100%;
  height: auto;
  overflow: hidden;
  text-align: center;
  font-size: 15px;
  font-weight: 500;
  color: #0090e4;
}
.btn.link_txt.link_txt::after {
  display: inline-block;
  vertical-align: middle;
  margin-left: 5px;
  margin-top: -3px;
  content: "";
  width: 15px;
  height: 15px;
  background: url(../images/arrow_right_blue.svg) no-repeat 50% 50%;
}
hr {
  margin-top: 20px;
  margin-bottom: 20px;
}
.btn.two_line_link_txt {
  display: block;
  width: 100%;
  height: auto;
  text-decoration: underline;
  padding-bottom: 3px;
  overflow: hidden;
  text-align: center;
  font-size: 15px;
  font-weight: 500;
  color: #0090e4;
}

/* 카메라 영역 */
.cam_container {
  padding-left: 18px;
  padding-right: 18px;
  padding-top: 30px;
  padding-bottom: 5px;
  background-color: black;
}

.cam_height_full {
  position: relative;
  height: 100%;
  overflow-y: auto;
}
.cam_header {
  display: flex;
  justify-content: center;
  padding: 30px 30px;
  height: 15%;
  /* background: black; */
}

.cam_bottom {
  display: flex;
  justify-content: center;
  padding-top: 68vh;
}
.cam_btn,
.cam_btn:hover,
.cam_btn:focus {
  height: 40px;
  width: 20%;
  background: #fff;
  border-color: #fff;
  color: #fff;
  outline: none;
  box-shadow: none;
  border-radius: 80px;
  margin-bottom: 10px;
}

.customer_center {
  color: #0090e4 !important;
  font-weight: bold;
  margin-right: 15px;
  text-decoration: underline;
}

/* INPUT 영역*/
.form-group .input-area {
  display: flex; /* justify-content: space-between; */
}
.form-group .input-area .form-control.phone {
  width: 30%;
}
.form-group .input-area .hyphen {
  width: 5%;
  text-align: center;
  padding-top: 10px;
}
.form-group.authen {
  position: relative;
}
.form-group.authen .timer {
  position: absolute;
  right: 12px;
  top: 43px;
  font-size: 12px;
  color: rgb(216, 17, 17);
}
.form-group.guide {
  position: relative;
}
.form-group.guide .form-control {
  padding-right: 37px;
}
.form-group.guide .timer {
  position: absolute;
  right: 12px;
  top: 43px;
  font-size: 12px;
  color: #999;
}

/* 모달 영역 */
.modal-content {
  overflow: hidden;
  width: 85%;
  position: absolute;
  left: 8%;
}

/*2021.08.27 추가*/

.agreement .modal-content {
  width: 100%;
  left: 0%;
  -webkit-text-size-adjust: none;
  -moz-text-size-adjust: none;
  -ms-text-size-adjust: none;
  font-size: 14px !important;
}

.agreement .modal-dialog {
  overflow-y: initial !important;
  -webkit-text-size-adjust: none;
  -moz-text-size-adjust: none;
  -ms-text-size-adjust: none;
  font-size: 14px !important;
}

.agreement .modal-body {
  overflow-y: auto;
  -webkit-text-size-adjust: none;
  -moz-text-size-adjust: none;
  -ms-text-size-adjust: none;
  max-height: 60vh;
  width: 100%;
  left: 0%;
  font-size: 14px !important;
}

.agreement .spanAgreement {
  -webkit-text-size-adjust: none;
  -moz-text-size-adjust: none;
  -ms-text-size-adjust: none;
  float: left;
  font-size: 14px !important;
}

.dialog .modal-content {
  overflow: hidden;
  width: 102%;
  left: -1%;
  top: 0;
  height: 100%;
}
.modal-content .info_box {
  margin-bottom: 15px;
  padding: 15px;
  padding-top: 0;
}
.modal-content .info {
  text-align: center;
  margin-bottom: 0;
  font-size: 16px;
  color: #777;
}
.modal-content .bottom_box {
  margin-bottom: 15px;
  padding: 15px;
}
.modal-content .txt_bottom {
  text-align: center;
  margin-bottom: 0;
  font-size: 14px;
  color: #3c3c3c;
}
.modal-header {
  justify-content: center;
  border-bottom: 0;
}
.dialog .modal-header {
  padding: 8px;
}
.modal-title.shot {
  padding: 20px;
  text-align: center;
  font-size: 15px;
}
.modal-title.shot1 {
  padding: 5px;
  text-align: center;
  font-size: 16px;
}
.btn_ok2,
.btn_ok2:hover,
.btn_ok2:focus {
  height: 38px;
  background: #0090e4;
  border-color: #0090e4;
  color: #fff;
  outline: none;
  box-shadow: none;
  font-size: 15px;
  font-weight: 300;
  border-radius: 5px;
  margin-bottom: 10px;
}
.btn_ok,
.btn_ok:hover,
.btn_ok:focus {
  height: 48px;
  background: #0090e4;
  border-color: #0090e4;
  color: #fff;
  outline: none;
  box-shadow: none;
  font-size: 16px;
  font-weight: 400;
  line-height: 30px;
  border-radius: 0;
}
.btn_cancel,
.btn_cancel:hover,
.btn_cancel:focus {
  height: 48px;
  background: #f0f0f0;
  border-color: #f0f0f0;
  color: #000;
  outline: none;
  box-shadow: none;
  font-size: 16px;
  font-weight: 400;
  line-height: 30px;
  border-radius: 0;
}
.btn-choice .btn-lt {
  padding-right: 0 !important;
}
.btn-choice .btn-mid {
  margin: auto;
  padding-right: 0 !important;
  padding-left: 0 !important;
}
.btn-choice .btn-rt {
  padding-left: 0 !important;
}
.cut_line {
  width: 330px;
  height: 1px;
  background-color: #d1cccc;
  display: block;
  margin: 0 auto;
  margin-bottom: 10px;
}
.modal.fade.show.shot {
  display: block;
}

/* 사진촬영 관련 영역 */
.container.shot {
  padding-top: 0;
}
.wrap_body.shot {
  width: 100%;
  background-color: #fff;
}
.wrap_body.shot .photo_zone {
  position: relative;
  top: 20px;
  left: 50%;
  transform: translateX(-50%);
  width: 336px;
  height: 252px;
  background-color: rgb(217, 217, 217);
}
.wrap_body.shot .photo_zone .guide_circle {
  position: absolute;
  bottom: 13px;
  left: 13px;
  width: 36px;
  height: 36px;
  padding: 10px;
  background-color: #000;
  opacity: 0.67;
  border-radius: 50%;
}
.wrap_body.shot .photo_zone .guide_circle .l_angle {
  position: absolute;
  top: 19px;
  left: 10px;
  width: 8px;
  height: 8px;
  border-left: 1px solid #fff;
  border-bottom: 1px solid #fff;
}
.wrap_body.shot .photo_zone .guide_circle .r_angle {
  position: absolute;
  top: 10px;
  left: 18px;
  width: 8px;
  height: 8px;
  border-right: 1px solid #fff;
  border-top: 1px solid #fff;
}
.wrap_body.shot .txt_area {
  position: relative;
  margin-top: 50px;
  color: #000;
  text-align: center;
}
.wrap_body.shot .txt_area p {
  margin-bottom: 0;
  font-weight: normal;
  font-size: 16px;
}
.wrap_body.shot .txt_area span {
  font-weight: 600;
}
.shot_exam {
  position: relative;
  top: 0px;
  width: 100%; /*height: 305px;*/
  background-color: #fff;
  padding: 30px 0 20px 0;
}
.shot_exam img {
  display: block;
  width: 100%;
}
.shot_exam .exam_txt {
  display: inline-block;
  font-size: 16px;
  color: #1a1a1a;
  margin-bottom: 10px;
}
.shot_exam .agree_txt {
  font-size: 16px;
  color: #000;
  text-align: center;
  margin-top: 20px;
}
.shot_exam .btn {
  font-weight: normal;
}

.wrap_bottom span.txt {
  font-size: 14px;
  color: #777;
}
.wrap_bottom img {
  margin-top: 15px;
}

.effect_txt2 {
  position: relative;
}
.effect_txt2::after {
  content: "";
  display: block;
  position: absolute;
  left: 1px;
  bottom: -2px;
  width: 100%;
  height: 8px;
  background-color: #f8626c;
  opacity: 0.3;
}

/* 콤보박스 & 인풋 (폼 관련) 영역*/
.custom-control.custom-radio.left {
  margin-right: 30px;
  float: left;
}
.custom-control.custom-radio.right {
  float: left;
}
.horizon_combo_area::after {
  display: block;
  content: "";
  clear: both;
  margin-bottom: 15px;
}
.form-control.exam_mail {
  margin-bottom: 37px !important;
  font-size: 14px;
}

.caution_box.active {
  display: block;
}
.caution_box {
  display: none;
  height: 38px;
  background-color: rgb(48, 46, 46);
  border-radius: 20px 20px;
  text-align: center;
  padding-top: 2px;
  padding-right: 8px;
  margin-bottom: 20px;
}
.caution_box span {
  font-size: 14px;
  font-weight: 300;
  color: #fff;
}
.caution_box span.i_icon {
  font-weight: bold;
  font-size: 20px;
  color: #018dde;
}

/* 버튼 Set */
.btn {
  width: 100%;
  outline: none;
  box-shadow: none;
}
.btn_blue,
.btn_blue:hover,
.btn_blue:focus {
  height: 44px;
  background: #0090e4;
  border-color: #0090e4;
  color: #fff;
  outline: none;
  box-shadow: none;
  font-size: 15px;
  font-weight: 500;
  line-height: 30px;
}
.btn_white,
.btn_white:hover,
.btn_white:focus {
  height: 44px;
  background: #fff;
  border: solid 1px #d9d9d9;
  color: #959595;
  outline: none;
  box-shadow: none;
  font-size: 15px;
  font-weight: 500;
  line-height: 30px;
}
.btn_gray,
.btn_gray:hover,
.btn_gray:focus {
  height: 44px;
  background: #7d7d7d;
  border-color: #7d7d7d;
  color: #fff;
  outline: none;
  box-shadow: none;
  font-size: 15px;
  font-weight: 500;
  line-height: 30px;
}
.btn_big,
.btn_big:hover,
.btn_big:focus {
  height: 60px;
  font-size: 18px;
  font-weight: 500;
  line-height: 30px;
  margin-bottom: 40px;
}
.btn.btn_white {
  margin-bottom: 15px;
}

.btn-area .btn-lt {
  padding-right: 5px !important;
}
.btn-area .btn-rt {
  padding-left: 5px !important;
}

.btn.btn_bottom {
  position: relative;
  left: 18px;
  width: calc(100% - 36px);
  margin-bottom: 45px;
}

/* Form Set */
.form-control.form-inline {
  width: auto;
}
.form-control {
  height: 44px;
  color: #262626;
  /* color: #999; */
  background-color: #f6f6f6;
  border-color: #f6f6f6;
  outline: none !important;
  box-shadow: none !important;
}
.form-control::placeholder {
  color: #999;
}
.form-control input,
.form-control select option {
  color: #1a1a1a;
}
.form-group label {
  font-size: 14px;
  color: #999;
}

.custom-radio label {
  padding-left: 5px;
  font-size: 16px;
  line-height: 1.88;
}

.section_radio {
  margin-bottom: 8px;
  padding: 20px;
  background: #fff;
  border-radius: 6px;
}
.section_radio .custom-control-label {
  width: 100%;
}
.section_radio label {
  padding-left: 5px;
  font-size: 15px;
  line-height: 1.88;
  color: #3c3c3c;
}
.section_radio label .info01 {
  font-size: 16px;
  color: #1a1a1a;
  font-weight: 400;
}
.section_radio label .info02 {
  font-size: 16px;
  color: #3c3c3c;
  font-weight: 500;
}
.info03 {
  font-size: 16px;
  color: #3c3c3c;
  font-weight: 500;
  margin-bottom: 15px;
}
.info04 {
  font-size: 16px;
  color: black;
  font-weight: 400;
  line-height: 1.25;
}

.radio-wrap {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.radio-wrap input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}
.checkmark {
  position: relative;
  float: right;
  top: -18px;
  height: 19px;
  width: 19px;
  background-color: white;
  border: 1.5px solid rgb(215, 215, 215);
  border-radius: 50%;
}
.radio-wrap input:checked ~ .checkmark {
  background-color: white;
  border: 1.5px solid rgb(0, 125, 202);
}
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}
.radio-wrap input:checked ~ .checkmark:after {
  display: block;
}
.radio-wrap .checkmark:after {
  top: 1.49px;
  left: 1.49px;
  width: 13.2px;
  height: 13.2px;
  border-radius: 50%;
  background: rgb(0, 125, 202);
}

.memnumber_select .section_radio label {
  padding-left: 15px;
}
.section_radio.outline_radio,
.section_radio.outline_checkbox {
  padding: 15px 10px;
  border: 1px solid #dbdbdb;
}
.section_radio.outline_radio:focus-within,
.section_radio.outline_radio.active {
  border-color: #0090e4;
}

.custom-control-input {
  width: 20px;
  height: 30px;
}
.custom-control-label::before {
  top: calc(50% - 10px);
  width: 20px;
  height: 20px;
  outline: none;
  border-color: #d9d9d9 !important;
  box-shadow: none !important;
}
.custom-control-input:checked ~ .custom-control-label::before {
  color: #fff;
  border-color: #007dca !important;
  background-color: #007dca;
  box-shadow: none !important;
}
.custom-control-label::after {
  position: absolute;
  top: calc(50% - 8px);
  left: -22px;
  background-image: url("../images/check_gray.svg") !important;
  background-size: 20px;
}
.custom-radio .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("../images/check_white.svg") !important;
  background-size: 20px;
}

.section_radio.right_check .custom-control {
  padding-left: 0;
}
.section_radio.right_check .custom-control label {
  padding-left: 0;
  padding-right: 30px;
}
.section_radio.right_check .custom-control-label::before {
  left: unset;
  right: 0;
}
.section_radio.right_check .custom-control-label::after {
  left: unset;
  right: 2px;
}
.section_radio.right_check label .info02 {
  line-height: 1.25;
}

/* Table Set */
.tbl_ver {
  width: 100%;
}
.tbl_ver th {
  padding: 13px 0;
  width: 110px;
  font-size: 14px;
  font-weight: normal;
  color: #999;
  vertical-align: top;
}
.tbl_ver td {
  padding: 13px 0;
  font-size: 15px;
  font-weight: normal;
  color: #3c3c3c;
}
.tbl_ver.type_right {
  margin-top: 15px;
}
.tbl_ver.type_right th,
.tbl_ver.type_right td {
  padding: 6px 0;
}
.tbl_ver.type_right td {
  text-align: right;
}

/* 전출/전입 확인 테이블 Set */
.tbl_ver2 {
  width: 100%;
}
.tbl_ver2 th {
  padding: 10px 0;
  width: 45%;
  font-size: 14px;
  font-weight: normal;
  color: #999;
  vertical-align: top;
  text-align: right;
}
.tbl_ver2 td {
  padding: 10px 0;
  font-size: 15px;
  font-weight: normal;
  color: #0c0c0c;
  text-indent: 15%;
}

.tbl_ver3 {
  width: 100%;
}
.tbl_ver3 th {
  padding: 9px 0;
  width: 110px;
  font-size: 14px;
  font-weight: normal;
  color: #999;
  vertical-align: top;
}
.tbl_ver3 td {
  padding: 9px 0;
  font-size: 15px;
  font-weight: normal;
  color: #3c3c3c;
}

/* 요금청구서 */
.section_payment {
  position: relative;
  height: auto;
  overflow: hidden;
  margin-bottom: 20px;
  padding-bottom: 20px;
  border-top: solid 2px #000;
  border-bottom: solid 2px #000;
}
.section_payment .pay_sum {
  font-size: 15px;
  font-weight: normal;
  color: #1a1a1a;
}
.section_payment .pay_sum span {
  display: inline-block;
  margin-right: 5px;
  vertical-align: sub;
  font-size: 36px;
  font-weight: bold;
  color: #222;
}
.section_agreeterms {
  background: #f5f5f5;
}
.section_agreeterms .section_radio {
  position: relative;
  padding: 15px 8px;
}
.section_radio .linked_txt {
  position: absolute;
  top: 3px;
  right: 5px;
  display: block;
  float: right;
  font-size: 14px;
  color: #777;
  text-decoration: underline;
}

/*!* 약관 관련 *!
.section_terms { height: calc(100% - 50px); padding-bottom: 0;}
.section_terms .article_term { padding: 10px; height: calc(85vh - 130px); background: #efefef; font-size: 14px; color: #777;}*/

/* 약관 관련 */
.section_terms {
  height: calc(100vh - 50px);
  padding-bottom: 0;
}
.section_terms .article_term {
  padding: 10px;
  height: 100%;
  background: #efefef;
  font-size: 14px;
  color: #777;
}

/* 완료단계 페이지 */
.section_finished {
  position: relative;
  height: auto;
  overflow: hidden;
  margin-bottom: 30px;
}
.section_finished .icon_area {
  position: relative;
  margin: 0 auto;
  width: 80px;
  height: 80px;
  background: url(../images/check_blue.svg) no-repeat;
  background-size: 80px;
}
.section_finished .txt {
  width: 100%;
  text-align: center;
  font-size: 20px;
  font-weight: 600;
  color: #1a1a1a;
}
.section_finished .section_table {
  padding: 0 20px 20px;
  margin: 30px 5px 0;
  background: #f6f6f6;
  border-top: 2px solid rgba(0, 0, 0, 0.07);
  border-bottom: 2px solid rgba(0, 0, 0, 0.07);
}
.section_finished .section_table th {
  width: 90px;
}

.section_finished .section_table2 {
  padding: 0 20px 20px;
  margin: 30px 5px 0;
  background: #f6f6f6;
  border-top: 2px solid rgba(0, 0, 0, 0.07);
  border-bottom: 2px solid rgba(0, 0, 0, 0.07);
  margin-bottom: 20px;
}
.section_finished .section_table2 th {
  width: 90px;
}

/* 자동이체신청 */
.wrap_aotopayment .tit_small {
  padding: 6px 0;
  border-radius: 16px;
  background: #f5f5f5;
}
.wrap_aotopayment .tab-content {
  padding: 20px 5px 20px;
}
.wrap_aotopayment .cardnum_area {
  display: flex;
}
.wrap_aotopayment .cardnum_area .hyphen {
  padding-top: 7px;
}
.wrap_aotopayment .type_half div[class*="col-"] {
  position: relative;
}
.wrap_aotopayment .type_half div[class*="col-"]:first-child {
  padding-right: 5px;
}
.wrap_aotopayment .type_half div[class*="col-"]:last-child {
  padding-left: 5px;
}
.wrap_aotopayment .type_half div[class*="col-"] .unit {
  position: absolute;
  right: 15px;
  top: 12px;
  font-size: 15px;
  color: #999;
}
.wrap_aotopayment .type_half div[class*="col-"]:last-child .unit {
  right: 25px;
}
.wrap_aotopayment .type_half .form-control {
  padding-right: 30px;
}
.nav-tabs .nav-link {
  font-size: 18px;
  font-weight: bold;
  color: #999;
}
.nav-tabs .nav-link:focus {
  border-color: #fff #fff #fff;
}
.nav-tabs .nav-link:hover {
  border-color: #fff #fff transparent;
}
.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
  border-color: #fff #fff #dee2e6;
  border-bottom: 3px solid #333;
}

.modal .table_area {
  padding: 0 20px 20px;
}
.modal .table_area table {
  margin-top: 0;
}
.modal .table_area table th {
  width: 90px;
}
.modal .table_area table th,
.modal .table_area table td {
  padding: 4px 0;
  font-size: 16px;
  font-weight: normal;
  color: #777;
}

/* 회원가입 영역 */
.noline_checkbox {
  margin-bottom: 10px;
}
.noline_checkbox.all_agree {
  margin-top: 70px;
  margin-bottom: -10px;
}
.noline_checkbox span {
  font-size: 15px;
  font-weight: 500;
}
.noline_checkbox.all_agree span {
  font-size: 16px;
  font-weight: 600;
}
.noline_checkbox .linked_txt {
  position: absolute;
  top: 3px;
  right: 5px;
  display: block;
  float: right;
  font-size: 14px;
  color: #777;
  text-decoration: underline;
}
.sequen_area {
  display: flex;
  margin-top: 20px;
  margin-bottom: 30px;
}
.sequen_area .sequen_box {
  width: 76px;
  height: 22px;
  color: #fff;
  font-size: 13px;
  text-align: center;
  background-color: #000;
  border-radius: 20px;
  padding-right: 2px;
  font-weight: 400;
}
.sequen_area .gray_circle {
  width: 8px;
  height: 8px;
  margin: 7px 10px 7px 0;
  margin-right: 0;
  border-radius: 50%;
  background-color: #d3d3d3;
}
.sequen_area div:nth-child(1) {
  margin-left: 0;
  margin-right: 10px;
}
.sequen_area div:nth-child(3) {
  margin-left: 10px;
}
.agree_txt_box {
  margin-top: 70px;
  margin-bottom: 20px;
}
.agree_txt_box .agree_txt {
  font-size: 30px;
  line-height: 30px;
  font-weight: 500;
}
.service_txt {
  font-size: 14px;
  font-weight: 400;
  color: #777;
  margin-bottom: 0;
}
.effect_txt {
  position: relative;
}
.effect_txt::after {
  content: "";
  display: block;
  position: absolute;
  bottom: 2px;
  width: 100%;
  height: 15px;
  background-color: #0090e4;
  opacity: 0.2;
}
span.nec {
  color: red;
}
.btn.btn_white.address {
  margin-bottom: 0;
  font-size: 14px;
}
.zipcode_area .zip {
  padding-right: 10px;
}
.zipcode_area .btn_add {
  padding-left: 0;
}

.txt.nomargin {
  margin-bottom: 0;
}
.section_finished .icon_area.margintop {
  margin-top: 100px;
  margin-bottom: 20px;
}
.service_txt.center {
  text-align: center;
}

/* 로그인 영역 */
.head_txt {
  font-size: 28px;
  font-weight: bold;
  margin: 0;
  margin-bottom: 3px;
}
.form-group.mtmb {
  margin-top: 50px;
  margin-bottom: 10px;
}
.form-group.discord {
  border-bottom: 2px solid #e44700;
}
.form-control.h_big {
  height: 52px;
}
.info01.login {
  font-size: 14px;
  font-weight: 500;
}
.horizon_checkbox_area {
  margin-bottom: 80px;
}
.find_box {
  text-align: center;
  margin-top: 15px;
}
.find_box span {
  font-size: 13px;
  font-weight: 500;
}
.find_box .find {
  color: #0090e4;
  cursor: pointer;
}
.form-text.text_alert.bold {
  color: #e44700;
  font-size: 14px;
  font-weight: 500;
}
.signup_box {
  text-align: center;
  margin-top: 50px;
  margin-bottom: 37px;
}
.signup_box span {
  font-size: 14px;
  color: 1px solid #333;
  font-weight: 600;
}
.signup_box .signup {
  font-weight: 600;
  border-bottom: 1px solid #191919;
}

/* 아이디 찾기*/
.findid_txt,
.passreset_txt {
  font-size: 16px;
  color: #1a1a1a;
  font-weight: 500;
  margin-top: 20px;
  margin-bottom: 45px;
}
.row.btn-area.find {
  padding-left: 20px;
  padding-right: 20px;
}

/* 계약자 인증 */
.passreset_txt.mb-0 {
  line-height: 15px;
}

/* 마이페이지 */
.logout_box {
  text-align: center;
  margin-bottom: 25px;
}
.logout_box span {
  font-size: 15px;
  color: #777;
}
.logout_box .stick {
  position: relative;
  top: 12px;
  display: inline-block;
  width: 1px;
  height: 15px;
  margin: 10px 18px;
  background-color: #dedede;
}

/* App 공통 */
.app_main_toparea h1 {
  margin-right: 0;
  width: calc(100% - 40px);
}

/* splash */
.splash {
  position: relative;
  height: 100vh;
  overflow: hidden;
  background: url(../images/splashscreen_bg.png) no-repeat;
  background-size: cover;
}
.splash .progress_bar {
  position: absolute;
  bottom: 50px;
  left: auto;
  height: 5px;
  width: calc(100% - 36px);
  background: rgba(255, 255, 255, 0.3);
}
.splash .progress_bar .percent_bar {
  display: block;
  height: 5px;
  background: #fff;
}
.splash .info_txt {
  position: absolute;
  bottom: 115px;
  left: auto;
  width: calc(100% - 36px);
  font-size: 14px;
  font-weight: normal;
  color: #fff;
  letter-spacing: normal;
}
.splash .title {
  position: absolute;
  bottom: 149px;
  left: auto;
  width: calc(100% - 36px);
  font-size: 36px;
  font-weight: bold;
  color: #fff;
  letter-spacing: normal;
  line-height: 1.19;
}

/* App_메인 */
.app_main {
  background: url(../images/main_sb_img.png) no-repeat;
  background-position: calc(100% + 120px) 20px;
}
.app_main .app_main_toparea {
  position: relative;
  height: auto;
  overflow: hidden;
  padding: 0 10px;
}
.app_main .app_main_toparea .logo_area img {
  float: left;
  height: 24px;
  width: auto;
}
.app_main_toparea .go_home {
  float: right;
  width: 20px;
  height: 20px;
  background: url(../images/main_home_img.png) no-repeat;
  background-size: 18px 20px;
  cursor: pointer;
}
.app_main_toparea .go_home span {
  display: none;
  text-indent: -1000px;
}
.app_main .main_top_title {
  clear: both;
  position: relative;
  height: auto;
  overflow: hidden;
  padding: 65px 32px 30px;
  color: #1a1a1a;
}
.app_main .main_top_title .txt_hash {
  font-size: 18px;
  margin-bottom: 6px;
}
.app_main .main_top_title .title {
  font-size: 30px;
  font-weight: 600;
  line-height: 36px;
}
.app_main .main_top_title .title span {
  position: relative;
}
.app_main .main_top_title .title span::after {
  content: "";
  display: block;
  position: absolute;
  bottom: 2px;
  width: 100%;
  height: 15px;
  background-color: #0090e4;
  opacity: 0.2;
}
.app_main .cards_area {
  margin-top: 20px;
}
.app_main .cards_area .card {
  width: 100%;
  height: 128px;
  padding-top: 22px;
  padding-left: 32px;
  border-radius: 10px;
  border: none;
}
.app_main .cards_area .card .tit {
  font-size: 20px;
  font-weight: 500;
  color: #000;
  margin-bottom: 5px;
}
.app_main .cards_area .card .txt {
  font-size: 16px;
  font-weight: normal;
  color: #333;
  line-height: 1.25;
}
.app_main .cards_area .card.first .txt {
  color: #555;
}
.app_main .cards_area .card.first {
  background-color: #cfdee6;
  background-image: url(../images/main_mn01_img.png);
  background-repeat: no-repeat;
  background-position: calc(100% - 40px) 25px;
}
.app_main .cards_area .card.second {
  margin-top: -10px;
  background-color: #7eb6d8;
  background-image: url(../images/main_mn02_img.png);
  background-repeat: no-repeat;
  background-position: calc(100% - 40px) 25px;
}
.app_main .cards_area .card.third {
  margin-top: -10px;
  background-color: #2394d5;
  background-image: url(../images/main_mn03_img.png);
  background-repeat: no-repeat;
  background-position: calc(100% - 40px) center;
}
.app_main .link_txt {
  position: relative;
  margin-top: 18px;
  text-align: center;
  font-size: 15px;
  font-weight: 600;
}
.app_main .link_txt .linked {
  display: inline-block;
  margin-left: 5px;
  border-bottom: 1px solid #333;
}
.app_main .linked_logout {
  position: relative;
  margin-top: 18px;
  text-align: center;
  font-size: 15px;
  font-weight: 600;
  display: inline-block;
  border-bottom: 1px solid #333;
}

/* 이용가이드 */
.container.usageguide {
  clear: both;
  position: relative;
  overflow: auto;
  padding: 0 45px 50px;
  background: #f3f3f3;
}
.usageguide .sequen_area {
  margin-bottom: 10px;
}
.usageguide .title {
  font-size: 20px;
  font-weight: 600;
  color: #1a1a1a;
}
.usageguide .info_txt {
  font-size: 16px;
  font-weight: normal;
  color: #777;
}
.usageguide .phone {
  position: relative;
  margin-top: 20px;
  width: 100%;
  min-height: 565px;
  background-size: contain !important;
  background-repeat: no-repeat !important;
}
.usageguide .phone.step01 {
  background: url(../images/usageguide_step01.png);
}
.usageguide .phone.step02 {
  background: url(../images/usageguide_step02.png);
}
.usageguide .phone.step03 {
  background: url(../images/usageguide_step03_2.png);
}
.usageguide .phone.step04 {
  background: url(../images/usageguide_step04.png);
}
.usageguide .phone.step05 {
  background: url(../images/usageguide_step05.png);
}
.usageguide .phone.step06 {
  background: url(../images/usageguide_step06.png);
}
.usageguide .phone.step07 {
  background: url(../images/usageguide_step07.png);
}

/* 보정 */
.modal-title {
  flex-grow: 1;
  line-height: normal !important;
  font-size: 18px;
  font-weight: 600;
  line-height: 18px;
  text-align: center;
  color: #1a1a1a;
}
.modal.termsModal .modal-content {
  height: 100vh;
  border: none;
  border-radius: 0;
}
.modal.termsModal .modal-dialog {
  height: 100vh;
  margin: 0;
}
.modal.termsModal .modal-body.section_terms {
  height: calc(100vh - 59px);
  padding-bottom: 1rem;
}
.modal.termsModal .section_terms .article_term {
  overflow-y: auto;
}
.form-control:focus {
  border-color: #222;
}

/* 도시가스 사용계약 영역 */
.sign_box {
  width: 100%;
  height: 120px;
  background-color: rgb(237, 237, 237);
  border: 1px solid rgb(203, 203, 203);
  text-align: center;
  padding-top: 30px;
  margin-top: 20px;
}
.sign_box span {
  display: inline-block;
  font-size: 15px;
  color: rgb(153, 153, 153);
}
.bottom_txt {
  margin-top: 30px;
  margin-bottom: 10px;
  text-align: center;
}
.bottom_txt span {
  font-size: 15px;
  color: rgba(60, 60, 60);
}
.bottom_txt .gray {
  font-size: 15px;
  color: rgb(153, 153, 153);
  margin-right: 5px;
}
.bottom_txt .space {
  margin-right: 15px;
}
.info01.f15 {
  font-size: 15px;
}

/* 기존 이력 확인 */
.row.btn-area.history_check {
  padding-left: 20px;
  padding-right: 20px;
}
.check_txt_box {
  text-align: center;
  margin-top: 30px;
}
p.history_check_txt {
  font-size: 16px;
  font-weight: bold;
  color: rgb(26, 26, 26);
  line-height: 5px;
}
.custom_info_box {
  width: 100%;
  height: 230px;
  background-color: rgb(246, 246, 246);
  margin-top: 40px;
  border-top: 1px solid rgb(217, 217, 217);
  border-bottom: 1px solid rgb(217, 217, 217);
  padding: 20px;
}
.custom_info_box2 {
  width: 100%;
  height: 220px;
  background-color: rgb(246, 246, 246);
  margin-top: 40px;
  border-top: 1px solid rgb(217, 217, 217);
  border-bottom: 1px solid rgb(217, 217, 217);
  padding: 20px;
}
.custom_info_box th {
  padding: 0;
}
.custom_info_box td {
  padding: 5px;
  text-align: right;
  font-size: 14px;
}
.custom_info_title {
  font-size: 15px;
  font-weight: bold !important;
}

/* 에러 페이지 */
.section_error {
  position: relative;
  height: auto;
  overflow: hidden;
  margin-bottom: 30px;
}
.section_error .icon_area {
  position: relative;
  margin: 0 auto;
  margin-top: 100px;
  margin-bottom: 10px;
  width: 80px;
  height: 80px;
  background: url(../images/ico_404error.png) no-repeat;
  background-size: 80px;
}
.section_error .icon_area2 {
  position: relative;
  margin: 0 auto;
  margin-top: 100px;
  margin-bottom: 10px;
  width: 90px;
  height: 75px;
  background: url(../images/ico_500error.png) no-repeat;
  background-size: 80px;
}
.section_error .icon_area3 {
  position: relative;
  margin: 0 auto;
  margin-top: 100px;
  margin-bottom: 10px;
  width: 80px;
  height: 80px;
  background: url(../images/ico_time_error.svg) no-repeat;
  background-size: 80px;
}
.section_error .txt {
  width: 100%;
  margin-bottom: 30px;
  text-align: center;
  font-size: 20px;
  font-weight: 600;
  color: #1a1a1a;
}
.section_error .error_txt.center {
  font-size: 15px;
  color: rgb(119, 119, 119);
  line-height: 5px;
  text-align: center;
}

/* 심채영 - 텍스트 깨짐 방지 */
.section_error .error_txt.center2 {
  font-size: 14px;
  color: rgb(119, 119, 119);
  line-height: 5px;
  text-align: center;
}

/* 촬영 영역 (추가) */
.gray_circlebox {
  background-color: rgb(240, 240, 240);
  height: 80px;
  width: 100%;
  padding: 15px;
  border-radius: 50px;
}
.btn.btn_blue.margintop {
  margin-top: 30px;
}
.container.shot.gas .shot_exam {
  margin-top: 50px;
  padding-top: 0;
}
.container.shot.gas .photo_zone {
  height: 350px;
}
.app_main_toparea .go_close {
  float: right;
  width: 18px;
  height: 20px;
  background: url(../images/icon_close.svg) no-repeat;
  background-size: 18px 20px;
  cursor: pointer;
}
.app_main_toparea .go_close span {
  display: none;
  text-indent: -1000px;
}
.wrap_header .blank {
  width: 20px;
  height: 20px;
}
.row.btn-area.shot {
  padding-left: 20px;
  padding-right: 20px;
}

/* 20210726 디자인변경에 의한 수정 */
.exam_range {
  margin-top: 10px;
  height: 200px;
  background: url(../images/exam_shot_range01.png) no-repeat;
  background-size: contain;
}
.exam_meter {
  height: 196px;
  width: 125px;
  /* width: 45%; */
  /* object-fit: contain; */
  object-fit: fill;
}
.exam_meter1 {
  height: 196px;
  width: 125px;
  /* width: 45%; */
  margin-right: 10px;
  object-fit: fill;
}

.switch .switch-checkbox {
  display: none;
}

.switch input[type="checkbox"]:checked + .switch-label .ball {
  transform: translateX(24px);
}

.switch .switch-label {
  position: relative;
  top: -37px;
  height: 25px;
  width: 65px;
  border-radius: 50px;
  background-color: rgb(119, 119, 119);
}

.switch input[type="checkbox"]:checked + label {
  position: relative;
  height: 25px;
  width: 65px;
  border-radius: 50px;
  background-color: rgb(0, 144, 228);
}

.switch .ball {
  position: absolute;
  font-size: 13px;
  height: 20px;
  width: 20px;
  border-radius: 50%;
  top: 2.4px;
  left: 3px;
  transition: transform 0.2s linear;
  background-color: #f9f9f9;
  box-shadow: 1px 1px 1px #727272;
}

.switch input[type="checkbox"]:checked + label .ball {
  position: absolute;
  height: 20px;
  width: 20px;
  border-radius: 50%;
  top: 2.4px;
  left: 18px;
  transition: transform 0.2s linear;
  background-color: rgb(255, 255, 255);
  box-shadow: 1px 1px 1px #727272;
}

.switch-label .labelMsg {
  position: absolute;
  font-size: 11px;
  color: white;
  top: 2.6px;
}

.stepMvinImage {
  background: url(../images/step_mvin.png);
  position: relative;
  width: 100%;
  height: 0;
  padding-top: 97%;
  background-size: contain;
  background-repeat: no-repeat;
}

.stepMvotImage {
  background: url(../images/step_mvot.png);
  position: relative;
  width: 100%;
  height: 0;
  padding-top: 85%;
  background-size: contain;
  background-repeat: no-repeat;
}

/* IE 9+10 속성 */
@media screen and (min-width: 0\0) and (min-width: 768px) {
}

/* 미디어쿼리 ******************************************************/
/* Extra small devices (portrait phones, less than 576px) *********/
/* No media query for `xs` since this is the default in Bootstrap */

/* Small devices (landscape phones, ≥576px and up) ~sm*/
@media (min-width: 576px) {
}

/* Medium devices (tablets, ≥768px and up) ~md*/
@media (min-width: 768px) {
}

/* Large devices (desktops, ≥992px and up) ~lg*/
@media (min-width: 992px) {
}

/* Extra large devices (large desktops, ≥1200px and up) ~xl*/
@media (min-width: 1200px) {
}

@media (max-width: 991.98px) {
}

/* mobile only */
@media (max-width: 767px) {
}
/* Extra small devices (portrait phones, less than 576px) ≤xs*/
@media (max-width: 575px) {
}
